import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "url-parameters-supported-by-agent"
    }}>{`URL parameters supported by agent`}</h2>
    <h3 {...{
      "id": "you-can-push-the-following-events-to-any-analytics-platform"
    }}>{`You can push the following events to any analytics platform`}</h3>
    <p>{`You can control the state of the agent application with URL parameters`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`go_online`}</inlineCode>{` - Sets agent tools master presence into online state`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`go_offline`}</inlineCode>{` - Sets agent tools master presence into offline state`}</li>
    </ul>
    <p>{`Example URL: `}<inlineCode parentName="p">{`https://agent.smilee.fi/#go_online`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      